import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { FaTimes  } from "react-icons/fa";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";
import axios from "axios";
import { SearchPayinPayoutsContext } from "contexts/search/SearchPayinPayouts";
import SearchPayinPayoutsActions from "actions/SearchPayinPayoutsActions";
const { REACT_APP_API_URL } = process.env;


const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const _logOut = () => {
    sessionStorage.removeItem("token");
    navigate("/auth", { replace: true, });
    toast.warning("Logged out successfully")
    return;
  }
  const [searchedPayin, setSearchedPayin] = useState("");
  const [searchedPayinLoading, setSearchedPayinLoading] = useState(false);
  const [searchedPayoutsLoading, setSearchedPayoutsLoading] = useState(false);
  const { state, dispatch } = useContext(SearchPayinPayoutsContext);
  const 
  payinTransactionStatus = ["approved"]
  const checkForPayinOrPayoutRoute = (searchedTerm: string) => {
    const { pathname } = location;
    if (pathname.endsWith("payins") || pathname.endsWith("payins/")) {
      return _searchForPayin(searchedTerm)
    }
    else if (pathname.endsWith("payouts") || pathname.endsWith("payouts/")) {
      return _searchForPayouts(searchedTerm)
    }
    else{
      toast.warning("Please go to the Payin or Payout Page to search for a reference or status!!!")
    }
  }


  const _searchForPayouts = (searchedTerm: string) => {
      !searchedTerm && dispatch({
        type: SearchPayinPayoutsActions.clearPayoutsSearchResults,
      });
      setSearchedPayoutsLoading(prev => prev = true);
      const authentication = JSON.parse(sessionStorage.getItem("token"));
      const option = {
        headers: {
          "X-Auth-Token": authentication.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      };
      axios
      .get(
        searchedTerm !== "" && searchedTerm.includes("-") ? `${REACT_APP_API_URL}/ledger/services/admin/payouts?paymentReference=${searchedTerm.toUpperCase()}` : `${REACT_APP_API_URL}/ledger/services/admin/payouts?status=${searchedTerm.toUpperCase()}`,
        option
      )
      .then((res) => {
        console.log(res.data);
        searchedTerm !== "" && dispatch({
          type: SearchPayinPayoutsActions.payoutsSearchResults,
          payload: res.data,
        });
        console.log(state, "state")
        setSearchedPayoutsLoading(prev => prev = false);
        searchedTerm !== "" && toast.success(`Showing search results for ${searchedTerm.toUpperCase()}`)
      })
      .catch((err) => {
        searchedTerm !== "" && toast.error(err.message)
        setSearchedPayoutsLoading(prev => prev = false);
      });
    }



  const _searchForPayin = (searchedTerm: string) => {
      !searchedTerm && dispatch({
        type: SearchPayinPayoutsActions.clearPayinsSearchResults,
      });
      setSearchedPayinLoading(prev => prev = true);
      const authentication = JSON.parse(sessionStorage.getItem("token"));
      const option = {
        headers: {
          "X-Auth-Token": authentication.token,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      };
      axios
        .get(
          // searchedTerm == ""
          //   ?
          //   `${REACT_APP_API_URL}/ledger/services/admin/payins`
          //   :
            searchedTerm !== "" && searchedTerm.includes("-") || !
            payinTransactionStatus.includes(searchedTerm.toLowerCase()) ? `${REACT_APP_API_URL}/ledger/services/admin/payins?transactionReference=${searchedTerm.toUpperCase()}` : `${REACT_APP_API_URL}/ledger/services/admin/payins?status=${searchedTerm.toUpperCase()}`,
          option
        )
        .then((res) => {
          console.log(res.data);
          searchedTerm !== "" && dispatch({
            type: SearchPayinPayoutsActions.payinsSearchResults,
            payload: res.data,
          });
          console.log(state, "state")
          setSearchedPayinLoading(prev => prev = false);
          searchedTerm !== "" && toast.success(`Showing search results for ${searchedTerm.toUpperCase()}`)
        })
        .catch((err) => {
          searchedTerm !== "" && toast.error(err.message)
          setSearchedPayinLoading(prev => prev = false);
        });
    }
  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center justify-between rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px] relative">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            value={searchedPayin}
            className="h-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white w-full"
            onChange={(e) => {
              setSearchedPayin(e.target.value);
            }}
            onKeyDown={(e) => {
              {
                e.key == "Enter" &&
                  checkForPayinOrPayoutRoute(searchedPayin)
              }
            }}
          />
          <span className="flex justify-center items-center w-8 max-h-max p-2 pr-3 rounded-full dark:bg-navy-900 dark:text-white  bg-lightPrimary text-sm text-navy-700 cursor-pointer" onClick={() => {
              setSearchedPayin(prev => prev = "");
          }}>
          <FaTimes  /> 
          </span>
        </div>
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>
        {/* start Notification */}
        <Dropdown
          button={
            <p className="cursor-pointer">
              <IoMdNotificationsOutline className="h-4 w-4 text-gray-600 dark:text-white" />
            </p>
          }
          animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          children={
            <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-navy-700 dark:text-white">
                  Notification
                </p>
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  Mark all read
                </p>
              </div>
            </div>
          }
          classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
        />
        {/* start Horizon PRO */}
        <div
          className="cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          {darkmode ? (
            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
          ) : (
            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex h-28 w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, Admin
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <p
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500 cursor-pointer"
                  onClick={() => _logOut()}
                >
                  Log Out
                </p>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
