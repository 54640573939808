

import { useRef } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
export default function Authenticated(props: { children?: JSX.Element }) {
    const hasSession = useRef(!!sessionStorage.getItem("token"))
    const location = useLocation();
    if (!!hasSession.current) {
        // toast.warning("You have to be loggled in to access that page")
        // if(location.pathname === "/auth") <Navigate to="admin" /> Trying to go to admin from login
        return <Outlet />
    }
    return (
        <>
            {/* ({toast.warning("Please log in first")} */}
            {/* <Navigate to="auth" state={{ from: location }} replace /> */}
            <Navigate to="auth" />
        </>)
}