import { useReducer } from "react";
import { createContext } from "react"
import SearchPayinPayoutsReducer, { SearchPayinPayoutsState } from "../../reducers/search/SearchPayinPayoutsReducer";

export const SearchPayinPayoutsContext = createContext(null)

const SearchPayinPayoutsContextProvider = (props: { children: JSX.Element }) => {
    const { children } = props;
    const [state, dispatch] = useReducer(SearchPayinPayoutsReducer, SearchPayinPayoutsState);
    return (
        <SearchPayinPayoutsContext.Provider value={{state, dispatch}}>
            {children}
        </SearchPayinPayoutsContext.Provider>
    )
}

export default SearchPayinPayoutsContextProvider