import SearchPayinPayoutsActions from "actions/SearchPayinPayoutsActions";

export const SearchPayinPayoutsState: { payinsResults: any[], payoutsResults: any[] } = {
  payinsResults: [],
  payoutsResults: [],
}

type actionTypes = {
  type: string,
  payload: any
}

const SearchPayinPayoutsReducer = (state = SearchPayinPayoutsState, { type, payload }: actionTypes) => {
  const { payinsSearchResults, clearPayinsSearchResults, payoutsSearchResults, clearPayoutsSearchResults } = SearchPayinPayoutsActions
  switch (type) {
    case payinsSearchResults:
      state.payinsResults.push(payload);
      return { ...state, };
    case clearPayinsSearchResults:
      state.payinsResults = [];
      return { ...state, };
    case payoutsSearchResults:
      state.payoutsResults.push(payload);
      return { ...state, };
    case clearPayoutsSearchResults:
      state.payoutsResults = [];
      return { ...state, };
    default:
      return state
  }
}

export default SearchPayinPayoutsReducer
