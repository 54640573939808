import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import PayinPage from './components/PayinPage'
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from "./components/Modal";
import { SearchPayinPayoutsContext } from 'contexts/search/SearchPayinPayouts';
import SearchPayinPayoutsActions from 'actions/SearchPayinPayoutsActions';
const { REACT_APP_API_URL } = process.env;

function PayinData() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [createdPayin, setCreatedPayin] = useState(false);
  const { state, dispatch } = useContext(SearchPayinPayoutsContext);
  useCallback(
    () => {
      dispatch({
        type: SearchPayinPayoutsActions.payinsSearchResults,
        payload: [],
      });
      
    },
    [state],
  )
  
  const _fetchPayins = () => {
    const authentication = JSON.parse(sessionStorage.getItem("token"));
    
    const option = {
      headers: {
        "X-Auth-Token": authentication.token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    axios
      .get(
        `${REACT_APP_API_URL}/ledger/services/admin/payins`,
        option
      )
      .then((res) => {
        if (res.status ===  401) {
          sessionStorage.removeItem("token");
          navigate("/auth");
          toast.warning("Token Expired, Please login again")
          return;
        }
        console.log(res.data);
        toast.success("Fetched Successfully");
        setData(data => data = res.data)
      })

      .catch((err) => {
        if (err.message.toLowerCase() === "token could not be verified") {
          sessionStorage.removeItem("token");
          navigate("/auth");
          toast.warning("Token Expired, Please login again")
          return;
        }
        console.log(err);

        toast.error(err.message)
      }
      );
    console.log(data, 'data');
  }

  useEffect(() => {
    _fetchPayins()
  }, []);
  // When it gets updated
  useEffect(() => {
    if (createdPayin) {
      _fetchPayins()
    }
    return;
  }, [createdPayin]);
  const [showModal, setShowModal] = useState(false);

  return (

    <div className="mt-12">
      <div className="flex items-center justify-end">
        <a
          href="#"
          className="mb-4 mr-2 rounded-md bg-blue-600
         px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-800"
          onClick={() => setShowModal(true)}
        >
          Create New Payin
        </a>
      </div>
      <Modal
        data={data}
        isVisible={showModal}
        onClose={() => setShowModal(false)}
        setCreatedPayin={setCreatedPayin}
      />
      <PayinPage data={data} setData={setData} content />
      {/* <CreatePayinPage /> */}
    </div>
  );
}

export default PayinData;
