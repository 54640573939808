import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Read from './Read';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from 'components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiSearch } from "react-icons/fi";
import { SearchPayinPayoutsContext } from 'contexts/search/SearchPayinPayouts';


const { REACT_APP_API_URL } = process.env;
function PayinPage({ data }: any) {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [payinData, setPayinData] = useState(data);
  const [formerData, setFormerData] = useState(data);
  const [searchpayinData, setSearchPayinData] = useState(data);
  const [searchedPayin, setSearchedPayin] = useState("");
  const [searchedPayinLoading, setSearchedPayinLoading] = useState(false);
  const [searchedPayinHeading, setSearchedPayinHeading] = useState(false);
// console.log(data, "the data")
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => { 
    setPayinData(data)
    setLoading(prev => prev = false)
  }, [data])
  const { state, dispatch } = useContext(SearchPayinPayoutsContext);
console.log(state, "I got the state");
useEffect(
  () => {
      if(state.payinsResults.length !== 0) {
            // console.log(state.payinsResults)
            setFormerData(payinData);
            setPayinData(state.payinsResults[0])
            console.log("got payin")
          }
          else{
            setPayinData(formerData)
          }
  },
  [state],
)
  
  // console.log(payinData, "data")
  const pageSize = payinData.pageSize;
  let pageNumber = payinData.pageNumber;
  const totalPages = payinData.totalPages;
  const records = payinData.payInTransactions;
  const totalRecords = payinData.totalPayInTransactions;
  // const nPage = Math.ceil(payinData.payInTransactions?.length / pageSize);
  // const numbers = [...Array(nPage + 1).keys()].slice(1);
  const numbers = {}

  // function prevPage() {
  //   if (pageNumber !== 1) {
  //     pageNumber = pageNumber - 1;
  //   }
  // }
  // function changeCPage(id: any) {
  //   pageNumber = id;
  // }
  // function nextPage() {
  //   if (pageNumber !== totalPages) {
  //     pageNumber = pageNumber + 1;
  //   }
  // }

  const [modalItem, setModalItem] = useState();
  const paginationStyles = "text-2xl w-6 h-6 hover:bg-gray-200 rounded-full cursor-pointer text-gray-700";
  const _previousPagination = () => {
    setLoading(prev => prev = true)
    const authentication = JSON.parse(sessionStorage.getItem("token"));
    
    const option = {
      headers: {
        "X-Auth-Token": authentication.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    axios
      .get(
        `${REACT_APP_API_URL}/ledger/services/admin/payins/?page=${(pageNumber - 1)}`,
        option
      )
      .then((res) => {
        console.log(res.data);
        setLoading(prev => prev = false);
        setPayinData(res.data);
        // window.history.replaceState(null, "New Set of Table Data", `${pathname}/p${(pageNumber + 1) < totalPages ? pageNumber + 1 : totalPages}`)
      })


      .catch((err) => {
        toast.error(err.message)
        console.log(err)
        setLoading(prev => prev = false)
      });
    console.log(data, 'data');
  }
  const _nextPagination = () => {
    setLoading(prev => prev = true)
    const authentication = JSON.parse(sessionStorage.getItem("token"));
    
    const option = {
      headers: {
        "X-Auth-Token": authentication.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    axios
      .get(
        `${REACT_APP_API_URL}/ledger/services/admin/payins/?page=${(pageNumber + 1) < totalPages ? pageNumber + 1 : totalPages}`,
        option
      )
      .then((res) => {
        console.log(res.data);
        setLoading(prev => prev = false);
        setPayinData(res.data);
        // window.history.replaceState(null, "New Set of Table Data", `${pathname}/p${(pageNumber + 1) < totalPages ? pageNumber + 1 : totalPages}`)
      })


      .catch((err) => {
        toast.error(err.message)
        console.log(err)
        setLoading(prev => prev = false)
      });
    console.log(data, 'data');
  }
  const transactionStatus = ["approved"]
  const _searchForPayin = (searchedTerm: string) => {
    // setSearchedPayout(prev => prev = "");
    setLoading(prev => prev = true)
    setSearchedPayinLoading(prev => prev = true);
    const authentication = JSON.parse(sessionStorage.getItem("token"));
    
    const option = {
      headers: {
        "X-Auth-Token": authentication.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };
    axios
      .get(
        searchedTerm == ""
          ?
          `${REACT_APP_API_URL}/ledger/services/admin/payins`
          :
          searchedTerm !== "" && searchedTerm.includes("-") || !transactionStatus.includes(searchedTerm.toLowerCase()) ? `${REACT_APP_API_URL}/ledger/services/admin/payins?transactionReference=${searchedTerm.toUpperCase()}` : `${REACT_APP_API_URL}/ledger/services/admin/payins?status=${searchedTerm.toUpperCase()}`,
        option
      )
      .then((res) => {
        console.log(res.data);
        setLoading(prev => prev = false);
        setSearchedPayinLoading(prev => prev = false);
        setPayinData(res.data)
        searchedTerm !== "" && toast.success(`Showing search results for ${searchedTerm.toUpperCase()}`)
        setSearchedPayinHeading(prev => prev = true);
      })
      .catch((err) => {
        searchedTerm !== "" && toast.error(err.message)
        setLoading(prev => prev = false)
        setSearchedPayinLoading(prev => prev = false);
      });
    console.log(data, 'data');
  }
  return (
    <>
      {modalItem && <Read isVisible={showModal} onClose={() => { setShowModal(false); setModalItem(prev => prev = null) }} item={modalItem} />}
      <div>
        <div className="w-full overflow-auto border-2 shadow-lg">
          <div className='flex justify-between border-b-2'>
            <h3 className="ml-5 py-3 text-[15px] font-bold flex items-center gap-x-4">
              All Business Payin Transaction{" "}
              <span className="text-blue-200">{totalRecords}</span>
            </h3>
            {/* <div className="relative  flex h-[40px] md-max:h-[32px]  flex-grow items-center justify-around gap-2 rounded-full shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2"> */}
            {/* <div className="border my-[8px] py-2 w-[355px] flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="text"
                placeholder="Search a payin..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white"
                onChange={(e) => {
                  setSearchedPayin(e.target.value);
                  setSearchedPayinHeading(prev => prev = false)
                }}
                value={searchedPayin}
                onKeyDown={(e) => {
                  {
                    e.key == "Enter" &&
                      _searchForPayin(searchedPayin);
                  }
                }}
              />
              {searchedPayinLoading && (<svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
              </svg>)}
            </div> */}
            {/* </div> */}
            <div className="flex items-center gap-x-4 mr-8">
              <p className='text-gray-600'><span>{(pageSize * (pageNumber - 1)) + 1}-</span>{(pageSize * pageNumber) > totalRecords ? totalRecords : (pageSize * pageNumber)} of <span>{totalRecords}</span></p>
              <MdChevronLeft className={`${paginationStyles} ${pageNumber == 1 && "text-gray-100  opacity-50 cursor-not-allowed"}`} onClick={() => {
                if (pageNumber == 1) return;
                _previousPagination();
              }} />
              <MdChevronRight className={`${paginationStyles} ${pageNumber == totalPages && "text-gray-100  opacity-50 cursor-not-allowed"}`} onClick={() => {

                if (pageNumber == totalPages) return;
                _nextPagination()
              }} />
            </div>
          </div>
          {loading && <div className='w-full flex justify-center items-center'>
            <Loader />
          </div>}
          {!totalRecords && (<div className="text-xl font-bold text-navy-700 dark:text-white md-max:text-sm text-center">
            NO RESULTS FOUND
          </div>)}
          {(!loading && totalRecords) && <table className="w-full" id="tableID">
            <thead className="border-b-2">
              <tr className="m-2 text-left text-[14px] uppercase text-blue-300">
                <th className="px-4 py-3 font-bold dark:text-white">Reference</th>
                <th className="px-4 py-3 font-bold dark:text-white">Currency</th>
                <th className="px-4 py-3 font-bold dark:text-white">Reference</th>
                <th className="px-4 py-3 font-bold dark:text-white">Amount</th>
                <th className="px-4 py-3 font-bold dark:text-white">Sender Name</th>
                <th className="px-4 py-3 font-bold dark:text-white">Transaction Date</th>
                <th className="px-4 py-3 font-bold dark:text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              {records?.map((item: any, i: any) => {
                return (
                  <tr
                    key={i}
                    className="cursor-pointer border-b-2 hover:bg-gray-200 dark:hover:bg-blue-800"
                    onClick={() => { setShowModal(true); setModalItem(prev => prev = item) }}
                  >
                    <td className="w-1/2 px-4 py-3 text-blue-500 text-[14px] dark:text-white">
                      {item.transactionReference}
                    </td>
                    <td className="px-4 py-3 text-blue-500 dark:text-white">{item.currency}</td>
                    <td className="px-4 py-3 w-1/2 text-[14px] dark:text-white">{item.ledgerTransactionReference}</td>
                    <td className="px-4 py-3">{item.amount}</td>
                    <td className="px-4 py-3">{item.senderName}</td>
                    <td className="px-4 py-3">{item.transactionDate}</td>
                    <td className="px-4 py-3">{item.status}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>}
        </div>
      </div >
    </>
  );
}

export default PayinPage